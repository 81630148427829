import React, { useState } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { SyntheticEvent, useRef } from "react";
import { useGlossaryStore } from "../../../../shared/store/glossary";

const ConsumerActions = () => {
  const { exportAll } = useGlossaryStore();

  const menuButton = useRef<Menu>(null);
  const [isLoading, setIsLoading] = useState(false);

  const exportAllGlossaries = async () => {
    try {
      setIsLoading(true);
      const response = await exportAll();
      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split("filename=")[1].trim().replace(/"/g, "")
        : "glossaries.zip";

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/zip" })
      );
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Download error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const actionItems = [
    {
      label: "Export Glossary",
      command: async () => {
        await exportAllGlossaries();
      },
    },
  ];

  const handleOnClick = (event: SyntheticEvent) => {
    if (menuButton) {
      menuButton?.current?.toggle(event);
    }
  };

  return (
    <div>
      <Menu
        model={actionItems}
        popup
        ref={menuButton}
        id="popup_menu_actions"
      />
      <Button
        loading={isLoading}
        outlined
        className="border-gray-300"
        severity="secondary"
        label="Actions"
        icon="pi pi-caret-down"
        iconPos="right"
        onClick={handleOnClick}
        aria-controls="popup_menu_actions"
        aria-haspopup
      />
    </div>
  );
};
export default ConsumerActions;
