import { linguistnowAPI } from "./index";
import { Account } from "../../types/Account";
import { ConsumerTrial } from "../../types/consumer/ConsumerTrial";
import { ConsumerHasLocale } from "../../types/ConsumerHasLocale";
import { PostConsumerHasLocaleFormalityModelT } from "../../types/UpdateConsumerHasLocaleFormality";

export const AccountAPI = {
  createAccount: async (account: Account) => {
    return linguistnowAPI<{
      id: string;
      status: string;
    }>({
      url: "account/api/v1/account",
      method: "POST",
      data: account,
    });
  },
  getConsumerTrial: async (consumerId: string) => {
    return linguistnowAPI<ConsumerTrial>({
      url: `account/api/v1/account/${consumerId}/trial`,
    });
  },
  resetUserEmail: async (email: string) => {
    return linguistnowAPI<any>({
      url: "account/api/v1/account/resetpassword",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        email,
      },
      // if data is not specified for POST request, axios errors out with CORS
      // to me, it looks like axios does not supply body to POST request if data is not specified
      // and BE expects POST request to have body, and returns no Access-Control-Allow-Origin header which triggers CORS error
      // OR maybe it's just Content-Type header that is sent when 'data=null', and not sent when 'data' is omitted from config
      data: null,
    });
  },
  getConsumerHasLocales: async (consumerId: string) => {
    return linguistnowAPI<{
      consumerHasLocales: ConsumerHasLocale[] | null;
    }>({
      url: `account/api/v1/account/${consumerId}/consumerhaslocale`,
    });
  },
  updateConsumerHasLocales: async (
    consumerId: string,
    consumerHasLocales: PostConsumerHasLocaleFormalityModelT[]
  ) => {
    return linguistnowAPI<{
      consumerHasLocales: ConsumerHasLocale[];
      status: string;
    }>({
      url: `account/api/v1/account/${consumerId}/consumerhaslocale`,
      method: "POST",
      data: { consumerHasLocales },
    });
  },
};

export default AccountAPI;
