import { ConsumerHasLocale } from "../types/ConsumerHasLocale";
import { Locale } from "../types/Locale";
import { useMemo, useState } from "react";
import { NO_LANGUAGE_SELECTED } from "../constants";

const useLocalePairSelection = (
  consumerHasLocales: ConsumerHasLocale[],
  locales: Locale[],
  languagePairMetadata?: Map<number, number>,
  glossaryTermsMetadata?: Map<number, number>
) => {
  const [selectedSourceLocaleId, setSelectedSourceLocaleId] =
    useState(NO_LANGUAGE_SELECTED);
  const [selectedTargetLocaleId, setSelectedTargetLocaleId] =
    useState(NO_LANGUAGE_SELECTED);

  const localeSelectOptions = useMemo(() => {
    const idLocalesMap = locales.reduce<{ [key: number]: Locale }>(
      (accumulator, currentValue) => {
        accumulator[currentValue.id] = currentValue;
        return accumulator;
      },
      {}
    );

    return consumerHasLocales
      .map((chl) => {
        if (!idLocalesMap[chl.localeId]) {
          return null;
        }

        // Even if mtDisplayName is not defined, we'd like to use locale.description as this locale can still should be
        // available for selection.
        const localeName =
          idLocalesMap[chl.localeId].mtDisplayName ||
          idLocalesMap[chl.localeId].description;
        const localeId = chl.localeId;
        return {
          name: localeName,
          label: localeName,
          id: localeId,
          value: localeId,
          metadata: {
            languagePairsCount: languagePairMetadata
              ? languagePairMetadata.get(localeId)
              : null,
          },
        };
      })
      .filter((item) => item)
      .sort((a, b) => {
        if (a && b && a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 0;
      });
  }, [locales, consumerHasLocales, languagePairMetadata]);

  const excludedSelectedSourceLocaleSelectOptions = useMemo(() => {
    return localeSelectOptions
      .filter((item) => item?.id !== selectedSourceLocaleId)
      .map((item) => ({
        ...item,
        metadata: {
          termsCount:
            glossaryTermsMetadata && item != null
              ? glossaryTermsMetadata.get(item.id)
              : null,
        },
      }));
  }, [selectedSourceLocaleId, localeSelectOptions, glossaryTermsMetadata]);

  return {
    sourceLocaleOptions: localeSelectOptions,
    targetLocaleOptions: excludedSelectedSourceLocaleSelectOptions,
    selectedSourceLocaleId,
    setSelectedSourceLocaleId,
    selectedTargetLocaleId,
    setSelectedTargetLocaleId,
  };
};

export default useLocalePairSelection;
