import { Consumer } from "../../../types/consumer/Consumer";
import { StateSliceCreator } from "../../utils/types";
import { ConsumerAPI } from "../../../api/api-golinguist/consumerAPI";

export type fetchCurrentConsumerT = () => Promise<void>;
export type currentConsumerSliceT = {
  currentConsumer: Consumer;
  fetchCurrentConsumer: fetchCurrentConsumerT;
  hasPermission(permissionGroup: string, permission: string): boolean;
};

export const createCurrentConsumerSlice: StateSliceCreator<
  currentConsumerSliceT
> = (set, get) => ({
  currentConsumer: {
    selectedConsumerName: "",
    encryptedSelectedConsumerId: "",
    encryptedUserId: "",
    canSwitchConsumers: false,
    permissions: [],
    isSsoUser: false,
    isLioAdmin: false,
  },
  fetchCurrentConsumer: async () => {
    const response = await ConsumerAPI.getCurrentConsumer();
    set(() => ({
      currentConsumer: response,
    }));
  },
  hasPermission(permissionGroup: string, permission: string): boolean {
    const permissions = get().currentConsumer.permissions;
    return permissions.includes(`${permissionGroup}.${permission}`);
  },
});
