import React, { useState, useCallback, useEffect } from "react";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { FieldCategoryHeader, FieldHeader, TabHeader } from "./shared/Headers";
// import { UserAPI } from "../../../shared/api/api-phase-1/userAPI";
import { AccountAPI } from "../../../shared/api/api-golinguist/accountAPI";
import { usePersonalInfoStore } from "../../../shared/store/personalInfo";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { getDataTestIdPtObject } from "../../../shared/functions/utils";

type FormData = {
  firstName: string;
  lastName: string;
};

const PersonalInformation = () => {
  const { personalInfo, fetchPersonalInfo, updatePersonalInfo } =
    usePersonalInfoStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<FormData>();

  useEffect(() => {
    fetchPersonalInfo();
  }, []);

  useEffect(() => {
    setValue("firstName", personalInfo?.firstName || "");
    setValue("lastName", personalInfo?.lastName || "");
  }, [personalInfo]);

  const onResetPassword = useCallback(async () => {
    if (!personalInfo) return;

    setIsLoading(true);
    AccountAPI.resetUserEmail(personalInfo?.email).then(() => {
      setIsPasswordReset(true);
      setIsLoading(false);
    });
  }, [personalInfo]);

  const onUpdateBtnClick = useCallback(() => {
    const toggleEditState = () => setIsEditing((prevState) => !prevState);

    if (!isEditing) {
      toggleEditState();
      return;
    }

    const firstName = getValues().firstName.trim();
    const lastName = getValues().lastName.trim();

    if (
      (isEditing && personalInfo?.firstName !== firstName) ||
      personalInfo?.lastName !== lastName
    ) {
      handleSubmit(async () => {
        await updatePersonalInfo({ firstName, lastName });
        toggleEditState();
      })();
    } else {
      toggleEditState();
    }
  }, [isEditing, personalInfo, handleSubmit]);

  return (
    <Card className="mt-7 border">
      <TabHeader>Account details</TabHeader>
      <div className="mt-5 flex align-items-center justify-content-between">
        <FieldCategoryHeader>Personal information</FieldCategoryHeader>
        <Button
          onClick={onUpdateBtnClick}
          className="py-1 px-2 text-sm text-primary-700 fw-semibold"
          label={isEditing ? "Save changes" : "Update"}
          text
        />
      </div>
      <div className="d-flex gap-3">
        <div className="flex-grow-1">
          <FieldHeader className="mb-1">First Name</FieldHeader>
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            rules={{
              required: "First name is required",
              minLength: {
                value: 2,
                message: "At lest two characters required",
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <InputText
                disabled={!isEditing}
                id={name}
                onChange={onChange}
                value={value}
                className={classNames({
                  "p-invalid": error,
                  "w-100": true,
                  "border-gray-400": !error,
                  "bg-gray-50": !isEditing,
                })}
                pt={{
                  root: getDataTestIdPtObject(`editPersonalInfo-${name}`),
                }}
              />
            )}
          />
          <div className="text-sm text-danger">{errors.firstName?.message}</div>
        </div>
        <div className="flex-grow-1">
          <FieldHeader className="mb-1">Last Name</FieldHeader>
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            rules={{
              required: "Last name is required",
              minLength: {
                value: 2,
                message: "You must have at least two characters",
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <InputText
                disabled={!isEditing}
                id={name}
                onChange={onChange}
                value={value}
                className={classNames({
                  "p-invalid": error,
                  "w-100": true,
                  "border-gray-400": !error,
                  "bg-gray-50": !isEditing,
                })}
                pt={{
                  root: getDataTestIdPtObject(`editPersonalInfo-${name}`),
                }}
              />
            )}
          />
          <div className="text-sm text-danger">{errors.lastName?.message}</div>
        </div>
      </div>

      <FieldHeader>Email address</FieldHeader>
      <p className="field-description text-sm">
        Invoices will be sent to this email address.
      </p>
      <div className="field-input p-input-icon-left mt-1 w-100">
        <i className="envelop-icon pi pi-envelope text-gray-900" />
        <InputText
          className="w-100 border-gray-400 bg-gray-50"
          disabled
          value={personalInfo?.email || ""}
          placeholder="email@example.com"
        />
      </div>
      <h2 className="category-header mt-5 text-sm fw-bold text-gray-700">
        Security
      </h2>
      <FieldHeader>Password</FieldHeader>
      <Button
        disabled={isPasswordReset || isLoading}
        onClick={onResetPassword}
        className={`mt-2${isPasswordReset ? " bg-success opacity-90" : ""}`}
        icon={
          (isLoading && "pi pi-spin pi-spinner") ||
          (isPasswordReset && "pi pi-check")
        }
        label={isPasswordReset ? "Check your email" : "Reset Password"}
      ></Button>
    </Card>
  );
};

export default PersonalInformation;
