import {
  linguistnowAPI,
  linguistnowAPIWithoutGlobalLoadingOverlay,
} from "./index";

export const ConsumerHasLocaleAPI = {
  getLanguagePairMetadata: async (encryptedConsumerId: string) => {
    return linguistnowAPI<Map<number, number>>({
      url: `linguistnow/consumerhaslocale/${encryptedConsumerId}/languagepairmetadata`,
    });
  },
  getGlossaryTermsMetadata: async (
    encryptedConsumerId: string,
    sourceLocale: string
  ) => {
    return linguistnowAPIWithoutGlobalLoadingOverlay<Map<number, number>>({
      url: `linguistnow/consumerhaslocale/${encryptedConsumerId}/glossarytermsmetadata?sourceLocale=${sourceLocale}`,
    });
  },
};
