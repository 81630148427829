import { StateSliceCreator } from "../../utils/types";
import {
  createCurrentConsumerSlice,
  currentConsumerSliceT,
} from "./currentConsumerSlice";
import { ConsumerHasLocale } from "../../../types/ConsumerHasLocale";
// import TrialSubAPI from "../../../api/api-phase-1/trialAndSubscriptionAPI";
import { ExtendedConsumerHasLocaleT } from "../../../../pages/settings/components/languages/LanguagesTabBody";
import { ACTION_STATUS, FORMALITY_TYPES } from "../../../constants";
import { AccountAPI } from "../../../api/api-golinguist/accountAPI";
import { ConsumerHasLocaleAPI } from "../../../api/api-golinguist/consumerHasLocaleAPI";

export type consumerHasLocalesSliceT = {
  consumerHasLocales: ConsumerHasLocale[];
  languagePairMetadata: Map<number, number>;
  glossaryTermsMetadata: Map<number, number>;
  fetchConsumerHasLocales: () => void;
  fetchLanguagePairMetadata: () => Promise<void>;
  fetchGlossaryTermsMetadata: (sourceLocale: string) => Promise<void>;
  postConsumerHasLocales: (
    locales: ExtendedConsumerHasLocaleT[]
  ) => Promise<void>;
} & currentConsumerSliceT;

const convertToNumberMap = (
  metadata: Map<number, number>
): Map<number, number> => {
  const resultMap = new Map<number, number>();
  Object.entries(metadata).forEach(([key, value]) => {
    resultMap.set(Number(key), Number(value));
  });
  return resultMap;
};

export const createConsumerHasLocalesSlice: StateSliceCreator<
  consumerHasLocalesSliceT
> = (set, get) => ({
  ...createCurrentConsumerSlice(set, get),
  consumerHasLocales: [],
  languagePairMetadata: new Map<number, number>(),
  glossaryTermsMetadata: new Map<number, number>(),
  fetchConsumerHasLocales: async () => {
    if (get().currentConsumer.encryptedSelectedConsumerId.length === 0) {
      await get().fetchCurrentConsumer();
    }

    const response = await AccountAPI.getConsumerHasLocales(
      get().currentConsumer.encryptedSelectedConsumerId
    );
    set(() => ({
      consumerHasLocales: response.consumerHasLocales || [],
    }));
  },
  postConsumerHasLocales: async (locales) => {
    if (locales.length === 0) return;

    if (get().currentConsumer.encryptedSelectedConsumerId.length === 0) {
      await get().fetchCurrentConsumer();
    }

    const consumerHasLocalesForApi = locales.map((item) => {
      return {
        id: item.consumerHasLocaleId || null,
        localeId: item.localeId || null,
        formalityType: item.formalityType || FORMALITY_TYPES.LIO_DEFAULT.value,
        status: item.consumerHasLocaleId
          ? ACTION_STATUS.update
          : ACTION_STATUS.create,
      } as const;
    });

    const resp = await AccountAPI.updateConsumerHasLocales(
      get().currentConsumer.encryptedSelectedConsumerId,
      consumerHasLocalesForApi
    );

    if (resp.status == "failed") {
      console.error("Failed to update languages");
      return;
    }

    if (resp.status == "success" || resp.status == "partial success") {
      const updatedLocalesMap = resp.consumerHasLocales.reduce((acc, value) => {
        acc[value.id] = value;
        return acc;
      }, {} as { [key: number]: ConsumerHasLocale });

      set({
        consumerHasLocales: get().consumerHasLocales.map((chl) => {
          if (updatedLocalesMap[chl.id]) {
            return updatedLocalesMap[chl.id];
          }
          return chl;
        }),
      });
    }
  },
  fetchLanguagePairMetadata: async (): Promise<void> => {
    const languagePairMetadata =
      await ConsumerHasLocaleAPI.getLanguagePairMetadata(
        get().currentConsumer.encryptedSelectedConsumerId
      );
    set(() => ({
      languagePairMetadata: convertToNumberMap(languagePairMetadata),
    }));
  },
  fetchGlossaryTermsMetadata: async (sourceLocale: string): Promise<void> => {
    const glossaryTermsMetadata =
      await ConsumerHasLocaleAPI.getGlossaryTermsMetadata(
        get().currentConsumer.encryptedSelectedConsumerId,
        sourceLocale
      );
    set(() => ({
      glossaryTermsMetadata: convertToNumberMap(glossaryTermsMetadata),
    }));
  },
});
