import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
  DBUser,
  Role,
  User,
  UserCreatePayload,
  UserUpdatePayload,
} from "../types/User";
import { UserAPI } from "../api/api-golinguist/userAPI";
import { USER_STATUS_NAME_MAPPING } from "../constants";
interface UsersStore {
  users: User[];
  activeUsers: () => User[];
  roles: Role[];
  fetchRoles: () => Promise<void>;
  fetchUsers: () => void;
  createUser: (data: UserCreatePayload) => void;
  updateUser: (data: UserUpdatePayload) => void;
  disableUser: (data: number[]) => void;
  isLoading: boolean;
}

export const useUsersStore = create<UsersStore>()(
  devtools((set, get) => ({
    users: [] as User[],
    roles: [],
    isLoading: false,
    activeUsers: () => get().users.filter((i) => i.status !== "Disabled"),
    fetchRoles: async () => {
      if (get().roles.length < 1) {
        const resp = await UserAPI.getRoles();
        const roles = resp.map((role) => {
          return {
            id: role.id,
            name: role.name,
            label: role.label,
          };
        });
        set({ roles });
      }
    },
    fetchUsers: async () => {
      await get().fetchRoles();

      const resp = await UserAPI.getUsers();
      const users = resp.map((user) => getFormattedUserData(user, get().roles));
      set({ users });
    },
    createUser: async (data: UserCreatePayload) => {
      await get().fetchRoles();

      const resp = await UserAPI.createUser(data);
      if (resp.success && resp.user) {
        const user = getFormattedUserData(resp.user, get().roles);

        set({
          users: [...get().users, user],
        });
      }
    },
    updateUser: async (data) => {
      const resp = await UserAPI.updateUser(data);
      if (resp.success && resp.user) {
        const index = get().users.findIndex(
          (value) => value.id === resp.user?.id
        );
        if (index == -1) {
          return get().fetchUsers();
        }

        const user = getFormattedUserData(resp.user, get().roles);

        get().users.splice(index, 1, user);

        set({
          users: [...get().users],
        });
      }
    },
    disableUser: async (data: number[]) => {
      await UserAPI.disableUser(data);

      set({
        users: get().users.map((user) => ({
          ...user,
          status: data.includes(user.id) ? "Disabled" : user.status,
        })),
      });
    },
  }))
);

const getFormattedUserData = (user: DBUser, roles: Role[]) => {
  return {
    ...user,
    status:
      USER_STATUS_NAME_MAPPING[
        user.status as keyof typeof USER_STATUS_NAME_MAPPING
      ],
    role: roles.find((role) => role.id === user.roleId) as Role,
    createdAt: {
      label: getLabelFromTimestamp(user.createdAt),
      value: user.createdAt,
    },
    updatedAt: {
      label: getLabelFromTimestamp(user.updatedAt),
      value: user.updatedAt,
    },
  };
};

const getLabelFromTimestamp = (ts: number) => {
  return new Intl.DateTimeFormat(undefined, {
    dateStyle: "short",
    timeStyle: "short",
  }).format(new Date(ts));
};
