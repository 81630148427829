import { linguistnowAPI } from "./index";
import { Locale } from "../../types/Locale";
import { IntegrationType } from "../../types/IntegrationType";

export const LookupAPI = {
  getLocales: async () => {
    return linguistnowAPI<Locale[]>({
      url: "lookup-data/api/v1/locale",
    });
  },
  getIntegrationTypes: async () => {
    return linguistnowAPI<IntegrationType[]>({
      url: "lookup-data/api/v1/integrationtype",
    });
  },
};

export default LookupAPI;
