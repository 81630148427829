import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import emailSrc from "shared/images/email.svg";
import { getHsformLink } from "shared/functions/hsform-link";
import TermsAndConditions from "./TermsAndConditions";
import { IntegrationType } from "../../../shared/types/IntegrationType";
// import LookupAPI from "../../../shared/api/api-phase-1/lookupAPI";
// import UserManagementAPI from "../../../shared/api/api-phase-1/userManagementAPI";
// import { ConsumerHasIntegrationTypesIn } from "../../../shared/types/CreateConsumerIntegrationTypesData";
// import { WebUser } from "../../../shared/types/WebUser";
// import { UserAPI } from "../../../shared/api/api-phase-1/userAPI";
import { getGoLinguistDomain } from "../../../shared/api/utils";
import { validateEmailAddress } from "../../../shared/functions/utils";
import { useLocalesStore } from "../../../shared/store/locales";
// import { ACTION_STATUS, FORMALITY_TYPES } from "../../../shared/constants";
// import TrialSubAPI from "../../../shared/api/api-phase-1/trialAndSubscriptionAPI";
import { emailRegex } from "../../../shared/regex";
import useAppErrorsState from "../../../shared/store/app-errors";
import { SignupConsumerData } from "../../../shared/types/ConsumerData";
import AccountAPI from "../../../shared/api/api-golinguist/accountAPI";
import LookupAPI from "../../../shared/api/api-golinguist/lookupAPI";

interface AccountSignUpProps {
  isPilot?: boolean;
}

const passwordPattern =
  /^(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*()]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

export const AccountSignUp: React.FC<AccountSignUpProps> = (props) => {
  const [integrationTypes, setIntegrationTypes] =
    useState<Array<IntegrationType>>();
  const [showTaCAgreement, setShowTaCAgreement] = useState(false);

  const { locales, fetchLocales } = useLocalesStore();
  const { resetServerError, setIsSignupError } = useAppErrorsState();

  useEffect(() => {
    if (locales.length < 1) {
      fetchLocales();
    }
  }, []);

  const navigate = useNavigate();

  const createNewAccount = async (newConsumer: SignupConsumerData) => {
    let accountPromise;

    try {
      const newAccountRequest = {
        email: newConsumer.consumerEmail,
        password: newConsumer.consumerPassword,
        firstName: newConsumer.consumerFirstName,
        lastName: newConsumer.consumerLastName,
        consumerTitle: newConsumer.consumerTitle,
        companyName: newConsumer.companyName,
        integrationTypeIds: newConsumer.selectedIntegrationTypeIds,
        isToCAccepted: newConsumer.isToCAccepted,
      };
      accountPromise = await AccountAPI.createAccount(newAccountRequest);
    } catch (e) {
      displaySignupError();
      return;
    }

    if (!accountPromise)
      throw "Account creation failed to return a response body.";
    {
      /* If status is 'succeed' then they are blacklisted so redirect to account created page */
    }
    if (accountPromise.status === "succeed" || !props.isPilot) {
      navigate("/account-created");
    } else {
      navigate("/account-created", {
        state: {
          isPilot: true,
        },
      });
    }
  };

  function displaySignupError() {
    resetServerError();
    setIsSignupError(true);
  }

  useEffect(() => {
    LookupAPI.getIntegrationTypes().then(setIntegrationTypes);
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    reset,
    setValue,
  } = useForm<SignupConsumerData>();

  const validatePassword = (value: string) => {
    if (value !== watch("consumerPassword")) {
      return "Passwords do not match";
    }
  };

  const fields: string[] = watch([
    "consumerFirstName",
    "consumerLastName",
    "consumerEmail",
    "companyName",
    "consumerTitle",
    "consumerPassword",
    "consumerConfirmedPassword",
  ]);
  const atLeastOneIntegrationType = () =>
    getValues("selectedIntegrationTypeIds").length
      ? true
      : "Please select a Language I/O solution";

  const onTermsAgree = () => {
    setValue("isToCAccepted", true);
    createNewAccount(getValues());
  };

  const resetForm = () => {
    reset();
    setShowTaCAgreement(false);
  };

  return (
    <>
      {showTaCAgreement ? (
        <TermsAndConditions
          onTermsAgree={onTermsAgree}
          onTermsDecline={resetForm}
        />
      ) : (
        <div className="row">
          <div className="col-md-6">
            <div className="shadow p-3 mb-4 bg-white rounded">
              <form
                onSubmit={handleSubmit(() => {
                  setShowTaCAgreement(true);
                })}
              >
                <div className="container ">
                  <div className="row">
                    <div className="col">
                      <h2>Set up your trial account</h2>
                    </div>
                  </div>
                  <div className="row">
                    <div>
                      Already have an account?{" "}
                      <a href={getGoLinguistDomain() + "linguistnow/login"}>
                        Sign In
                      </a>
                    </div>
                  </div>
                  <div className="row mt-4 gap-3">
                    <div className="col">
                      <label htmlFor="consumerFirstName">First Name *</label>
                      <input
                        {...register("consumerFirstName", {
                          required: "First Name is required",
                          minLength: {
                            value: 2,
                            message: "You must have at least two characters",
                          },
                        })}
                        type="text"
                        className="form-control"
                        maxLength={90}
                        id="consumerFirstName"
                      />
                      {errors.consumerFirstName && (
                        <div className="text-danger" role="alert">
                          {errors.consumerFirstName.message}
                        </div>
                      )}
                    </div>
                    <div className="col">
                      <label htmlFor="consumerLastName">Last Name *</label>
                      <input
                        {...register("consumerLastName", {
                          required: "Last Name is required",
                          minLength: {
                            value: 2,
                            message: "You must have at least two characters",
                          },
                        })}
                        type="text"
                        className="form-control"
                        maxLength={90}
                        id="consumerLastName"
                      />
                      {errors.consumerLastName && (
                        <div className="text-danger" role="alert">
                          {errors.consumerLastName.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col">
                      <label htmlFor="consumerTitle">Title *</label>
                      <input
                        {...register("consumerTitle", {
                          required: "Title is required",
                        })}
                        type="text"
                        className="form-control"
                        maxLength={90}
                        id="title"
                      />
                      {errors.consumerTitle && (
                        <div className="text-danger" role="alert">
                          {errors.consumerTitle.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col">
                      <label htmlFor="consumerEmail">Company email *</label>
                      <input
                        {...register("consumerEmail", {
                          required: "Email is required",
                          pattern: {
                            value: emailRegex,
                            message:
                              "Entered value does not match email format",
                          },
                          validate: validateEmailAddress,
                        })}
                        type="email"
                        className="form-control"
                        maxLength={90}
                        id="emailAddress"
                        aria-describedby="emailAddressHelp"
                      />
                      {errors.consumerEmail && (
                        <div className="text-danger" role="alert">
                          {errors.consumerEmail.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col">
                      <label htmlFor="consumerPassword">Password *</label>
                      <input
                        {...register("consumerPassword", {
                          required: "Password is required",
                          pattern: {
                            value: passwordPattern,
                            message:
                              "Password must contain minimum of 8 characters, One uppercase letter, one special character, and one number.",
                          },
                        })}
                        type="password"
                        className="form-control"
                        maxLength={90}
                        id="consumerPassword"
                      />
                      {errors.consumerPassword && (
                        <div className="text-danger" role="alert">
                          {errors.consumerPassword.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col">
                      <label htmlFor="consumerConfirmedPassword">
                        Confirm Password *
                      </label>
                      <input
                        {...register("consumerConfirmedPassword", {
                          required: "Password is required",
                          pattern: {
                            value: passwordPattern,
                            message:
                              "Password must contain minimum of 8 characters, One uppercase letter, one special character, and one number.",
                          },
                          validate: validatePassword,
                        })}
                        type="password"
                        className="form-control"
                        maxLength={90}
                        id="consumerConfirmedPassword"
                      />
                      {errors.consumerConfirmedPassword && (
                        <div className="text-danger" role="alert">
                          {errors.consumerConfirmedPassword.message}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col">
                      <label htmlFor="companyName">Company Name *</label>
                      <input
                        {...register("companyName", {
                          required: "Company Name is required",
                        })}
                        type="text"
                        className="form-control"
                        maxLength={45}
                        id="companyName"
                      />
                      {errors.companyName && (
                        <div className="text-danger" role="alert">
                          {errors.companyName.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col">
                      <label className="mb-2" htmlFor="integrationTypeIds">
                        In addition to the trial, I am interested in the
                        following solutions: *
                      </label>
                      <div className="mb-4">
                        {integrationTypes?.map((integrationType, index) => {
                          return (
                            <label className="me-3 pe-1" key={index}>
                              <input
                                className="me-1"
                                type="checkbox"
                                value={integrationType.id}
                                {...register("selectedIntegrationTypeIds", {
                                  validate: atLeastOneIntegrationType,
                                })}
                              />
                              <span>{integrationType.name}</span>
                            </label>
                          );
                        })}
                        {errors.selectedIntegrationTypeIds && (
                          <div className="text-danger" role="alert">
                            {
                              // Typescript believes that errors.selectedIntegrationTypeIds is FieldError[].
                              // In reality it is just a FieldError hence the use of the dreaded ts-ignore comment.
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              errors.selectedIntegrationTypeIds.message
                            }
                          </div>
                        )}
                      </div>
                      <div
                        className="text-center alert alert-info"
                        role="alert"
                      >
                        All free trials come with access to our API and Machine
                        Translation portal. Please indicate if you are
                        interested in a CRM-specific integration or premium API
                        solution and a Language I/O representative will reach
                        out.
                      </div>
                    </div>
                    <small className="form-text text-muted">
                      * Required fields
                    </small>
                  </div>
                  <div className="row mb-3">
                    <div className="col text-center">
                      <button type="submit" className="btn btn-primary">
                        {props.isPilot ? "Continue" : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6 align-self-center px-5">
            <h2>
              Get a taste of Language I/O’s high quality machine translation
            </h2>
            <p className="pb-5 pt-3">
              Our trial experience provides access to 5,000 words of free
              machine translation using our API and machine translation portal
              (no credit card required). You’ll also have the opportunity to set
              up a glossary of up to 10 terms with specifications on how to
              translate those terms into your selected languages.
            </p>
            <p className="d-flex justify-content-center">
              <span
                id="contactLink"
                className="border border-primary border-2 p-3 rounded"
              >
                <img src={emailSrc} alt="email icon" className="pe-2" />{" "}
                <a
                  href={
                    "https://share.hsforms.com/1JD7KZom9RJO-H2Mbadh30g2dxtp" +
                    getHsformLink(fields)
                  }
                  className="text-decoration-underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Us
                </a>{" "}
                for Assistance
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};
