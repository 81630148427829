import React, { useCallback, useRef, useState } from "react";
import {
  Column,
  ColumnEditorOptions,
  ColumnFilterElementTemplateOptions,
  ColumnSortEvent,
} from "primereact/column";
import {
  DataTable,
  DataTableEditingRows,
  DataTableFilterMeta,
  DataTableRowEditCompleteEvent,
  DataTableRowEditEvent,
  DataTableSelectionMultipleChangeEvent,
  DataTableValueArray,
} from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { Dropdown } from "primereact/dropdown";
import { SelectItem } from "primereact/selectitem";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { ParsingRule } from "../../../../shared/types/ParsingRule";
import { useGlossaryStore } from "../../../../shared/store/glossary";
import {
  compareLocaleSensitive,
  getBodyClassName,
  getColumns,
  getStatusLabel,
} from "../../utils/GlossaryTableUtils";
import { RULE_TYPE, STATUS } from "../../utils/GlossaryTableConstants";
import { getDataTestIdPtObject } from "../../../../shared/functions/utils";
import { GLOSSARY_TABLE_COLUMN_FIELD } from "../../../../shared/constants";
import { useConsumerStore } from "../../../../shared/store/consumer";
import {
  Permission,
  PermissionGroup,
} from "../../../../shared/types/UserPermission";
import { InputNumber } from "primereact/inputnumber";

export interface TableProps {
  displayParsingRules: ParsingRule[];
  setDisplayParsingRules: React.Dispatch<React.SetStateAction<ParsingRule[]>>;
  modifiedParsingRules: ParsingRule[];
  setModifiedParsingRules: React.Dispatch<React.SetStateAction<ParsingRule[]>>;
  showEditConfirmation: boolean;
  addNewRow?: () => void;
  editingRows?: DataTableEditingRows;
  setEditingRows?: React.Dispatch<React.SetStateAction<DataTableEditingRows>>;
  selectedItems?: ParsingRule[];
  setSelectedItems?: React.Dispatch<React.SetStateAction<ParsingRule[]>>;
  dataTableSelection?: ParsingRule[];
  setDataTableSelection?: React.Dispatch<React.SetStateAction<ParsingRule[]>>;
}

export const ruleTypeBodyTemplate = (rowData: ParsingRule) => {
  return <span>{RULE_TYPE[rowData.ruleType].label}</span>;
};

export const caseSensitiveStatusBodyTemplate = (rowData: ParsingRule) => {
  return <Checkbox disabled checked={rowData.caseInsensitive} />;
};

export const noTranslateStatusBodyTemplate = (rowData: ParsingRule) => {
  return <Checkbox disabled checked={rowData.applyNoTranslateTag} />;
};

export const readOnlyBodyTemplate = (rowData: ParsingRule) => {
  return <Checkbox disabled checked={rowData.readOnly} />;
};

const maxReplacementOrder = 10_000;
const minReplacementOrder = -10_000;

export const TableContents = (props: TableProps) => {
  const { selectedLanguagePair, isLoading: isLoadingFetchParsingRules } =
    useGlossaryStore();
  const { hasPermission, currentConsumer } = useConsumerStore();

  const getFilterMenu = (field: string) => {
    switch (field) {
      case GLOSSARY_TABLE_COLUMN_FIELD.applyNoTranslate:
      case GLOSSARY_TABLE_COLUMN_FIELD.caseInsensitive:
      case GLOSSARY_TABLE_COLUMN_FIELD.status:
      case GLOSSARY_TABLE_COLUMN_FIELD.readOnly:
        return false;
    }
  };

  const validateInput = (
    sourceString: string,
    replacementString: string,
    regex: string,
    replacementOrder: string
  ) => {
    const validation = {
      valid: true,
      error: "",
    };
    if (sourceString.length === 0 || replacementString.length === 0) {
      validation.valid = false;
      validation.error = "Source or Replacement cannot be empty.";
    }
    if (sourceString.length > 196) {
      validation.valid = false;
      validation.error = "Source cannot be longer than 196 characters.";
    }
    if (replacementString.length > 196) {
      validation.valid = false;
      validation.error = "Replacement cannot be longer than 196 characters.";
    }
    if (regex.length > 450) {
      validation.valid = false;
      validation.error = "Regex cannot be longer than 450 characters.";
    }
    if (
      parseInt(replacementOrder) > maxReplacementOrder ||
      parseInt(replacementOrder) < minReplacementOrder
    ) {
      validation.valid = false;
      validation.error =
        "Replacement order cannot be higher than 10,000 or lower than -10,000";
    }

    return validation;
  };

  const validateRowEdit = (data: ParsingRule) => {
    const validationResult = validateInput(
      data.sourceString,
      data.replacementString,
      data.regex,
      data.replacementOrder
    );
    if (validationResult.error) {
      alert(validationResult.error);
    }
    return validationResult.valid;
  };

  const statusBodyTemplate = (rowData: ParsingRule) => {
    const statusIndicatorClassName =
      !props.showEditConfirmation &&
      (rowData.status === STATUS.PUBLISHED ||
        rowData.status === STATUS.REVERTED)
        ? "published-icon"
        : "edit-delete-icon";

    return (
      rowData.status && (
        <div className="d-flex align-items-center">
          <i className={`${statusIndicatorClassName} pi pi-circle-fill pr-3`} />
          {getStatusLabel(rowData)}
        </div>
      )
    );
  };

  const getColumnBody = (field: string) => {
    switch (field) {
      case GLOSSARY_TABLE_COLUMN_FIELD.applyNoTranslate:
        return noTranslateStatusBodyTemplate;
      case GLOSSARY_TABLE_COLUMN_FIELD.caseInsensitive:
        return caseSensitiveStatusBodyTemplate;
      case GLOSSARY_TABLE_COLUMN_FIELD.readOnly:
        return readOnlyBodyTemplate;
      case GLOSSARY_TABLE_COLUMN_FIELD.status:
        return statusBodyTemplate;
      case GLOSSARY_TABLE_COLUMN_FIELD.ruleType:
        return ruleTypeBodyTemplate;
      default:
        break;
    }
  };

  const checkboxEditor = (options: ColumnEditorOptions) => {
    return (
      <Checkbox
        checked={options.value}
        onChange={(e) => {
          if (options.editorCallback) {
            options.editorCallback(e.target.checked);
          }
        }}
      />
    );
  };

  const numberEditor = (options: ColumnEditorOptions) => {
    return (
      <InputNumber
        useGrouping={false}
        min={-10_000}
        max={10_000}
        value={options.value}
        onBlur={(e) => {
          if (options.editorCallback) {
            options.editorCallback(e.target.value);
          }
        }}
        onChange={(e) => {
          if (options.editorCallback) {
            options.editorCallback(e.value);
          }
        }}
      />
    );
  };

  const dropdownEditor = (options: ColumnEditorOptions) => {
    return (
      <Dropdown
        className="w-100"
        value={options.value}
        options={Object.values(RULE_TYPE)}
        onChange={(e) => {
          if (options.editorCallback) {
            options.editorCallback(e.value);
          }
        }}
      />
    );
  };

  const textAreaEditor = (options: ColumnEditorOptions) => {
    const isSourceColumn = options.field == GLOSSARY_TABLE_COLUMN_FIELD.source;
    const isReplacementColumn =
      options.field == GLOSSARY_TABLE_COLUMN_FIELD.replacement;
    const sourceLocaleIsRightToLeft =
      selectedLanguagePair && selectedLanguagePair.isSourceLocaleRtl;
    const targetLocaleIsRightToLeft =
      selectedLanguagePair && selectedLanguagePair.isTargetLocaleRtl;
    const displayRightToLeft =
      (isSourceColumn && sourceLocaleIsRightToLeft) ||
      (isReplacementColumn && targetLocaleIsRightToLeft);
    return (
      <InputTextarea
        className="w-100"
        dir={displayRightToLeft ? "rtl" : ""}
        autoResize={true}
        value={options.value}
        onChange={(e) => {
          if (options.editorCallback) {
            options.editorCallback(e.target.value);
          }
        }}
      />
    );
  };

  const cellEditor = (options: ColumnEditorOptions) => {
    switch (options.field) {
      case GLOSSARY_TABLE_COLUMN_FIELD.readOnly:
        break;
      case GLOSSARY_TABLE_COLUMN_FIELD.applyNoTranslate:
      case GLOSSARY_TABLE_COLUMN_FIELD.caseInsensitive:
        return checkboxEditor(options);
      case GLOSSARY_TABLE_COLUMN_FIELD.status:
        return statusBodyTemplate(options.rowData);
      case GLOSSARY_TABLE_COLUMN_FIELD.replacementOrder:
        return numberEditor(options);
      case GLOSSARY_TABLE_COLUMN_FIELD.ruleType:
        return dropdownEditor(options);
      default:
        return textAreaEditor(options);
    }
  };

  const defaultFilters = {
    sourceString: { value: "", matchMode: FilterMatchMode.CONTAINS },
    replacementString: { value: "", matchMode: FilterMatchMode.CONTAINS },
    regex: { value: "", matchMode: FilterMatchMode.CONTAINS },
    ruleType: { value: "", matchMode: FilterMatchMode.EQUALS },
    replacementOrder: { value: "", matchMode: FilterMatchMode.EQUALS },
    caseInsensitive: {
      // For the columns that use checkbox as filter control we want the value to be `undefined` by default
      // primereact's internal logic requires that, otherwise bug described in CN-737 occurs
      value: undefined,
      matchMode: FilterMatchMode.EQUALS,
    },
    applyNoTranslateTag: {
      // For the columns that use checkbox as filter control we want the value to be `undefined` by default
      // primereact's internal logic requires that, otherwise bug described in CN-737 occurs
      value: undefined,
      matchMode: FilterMatchMode.EQUALS,
    },
    readOnly: {
      // For the columns that use checkbox as filter control we want the value to be `undefined` by default
      // primereact's internal logic requires that, otherwise bug described in CN-737 occurs
      value: undefined,
      matchMode: FilterMatchMode.EQUALS,
    },
    status: { value: "", matchMode: FilterMatchMode.EQUALS },
  };

  const [filters, setFilters] = useState<DataTableFilterMeta>(defaultFilters);

  const clearFilters = () => {
    setFilters(defaultFilters);
  };

  const booleanFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <TriStateCheckbox
        pt={{
          checkbox: getDataTestIdPtObject(options.field + "FilterCheckbox"),
        }}
        value={options.value}
        onChange={(e) => options.filterApplyCallback(e.value)}
      />
    );
  };

  const statuses: SelectItem[] = [
    { label: STATUS.PUBLISHED, value: STATUS.PUBLISHED },
    { label: STATUS.NEW_TERM, value: STATUS.NEW_TERM },
    { label: STATUS.EDIT, value: STATUS.EDIT },
    { label: STATUS.DELETE, value: STATUS.DELETE },
    { label: STATUS.REVERTED, value: STATUS.REVERTED },
  ];

  const dropdownFilterTemplate = (field: string) => {
    const filterOptions =
      field == GLOSSARY_TABLE_COLUMN_FIELD.status
        ? statuses
        : Object.values(RULE_TYPE);
    const placeholder =
      field == GLOSSARY_TABLE_COLUMN_FIELD.status
        ? "Select a Status"
        : "Select a Rule Type";

    // eslint-disable-next-line react/display-name
    return (options: ColumnFilterElementTemplateOptions) => {
      return (
        <Dropdown
          value={options.value}
          options={filterOptions}
          onChange={(e) => options.filterApplyCallback(e.value, options.index)}
          placeholder={placeholder}
          className="p-column-filter"
          pt={{
            root: getDataTestIdPtObject(options.field + "FilterRoot"),
            select: getDataTestIdPtObject(options.field + "FilterSelect"),
            list: getDataTestIdPtObject(options.field + "FilterList"),
          }}
        />
      );
    };
  };

  const getFilterElement = (field: string) => {
    switch (field) {
      case GLOSSARY_TABLE_COLUMN_FIELD.applyNoTranslate:
      case GLOSSARY_TABLE_COLUMN_FIELD.caseInsensitive:
      case GLOSSARY_TABLE_COLUMN_FIELD.readOnly:
        return booleanFilterTemplate;
      case GLOSSARY_TABLE_COLUMN_FIELD.status:
      case GLOSSARY_TABLE_COLUMN_FIELD.ruleType:
        return dropdownFilterTemplate(field);
      default:
        break;
    }
  };

  const getFilterPlaceholder = (field: string, filterPlaceholder?: string) => {
    switch (field) {
      case GLOSSARY_TABLE_COLUMN_FIELD.applyNoTranslate:
      case GLOSSARY_TABLE_COLUMN_FIELD.caseInsensitive:
      case GLOSSARY_TABLE_COLUMN_FIELD.status:
      case GLOSSARY_TABLE_COLUMN_FIELD.readOnly:
        break;
      case GLOSSARY_TABLE_COLUMN_FIELD.source:
        return selectedLanguagePair
          ? "Search " + selectedLanguagePair?.sourceLocaleName
          : "Search";
      case GLOSSARY_TABLE_COLUMN_FIELD.replacement:
        return selectedLanguagePair
          ? "Search " + selectedLanguagePair?.targetLocaleName
          : "Search";
      default:
        return filterPlaceholder;
    }
  };

  const onRowEditChange = (editEvent: DataTableRowEditEvent) => {
    if (props.setEditingRows) {
      props.setEditingRows(editEvent.data);
    }
  };

  const onRowEditCancel = useCallback(
    (editEvent: DataTableRowEditEvent) => {
      const notSavedLocally = editEvent.data.status === null;

      if (notSavedLocally) {
        const removeRowFilter = ({ id }: ParsingRule) =>
          id !== editEvent.data.id;
        props.setDisplayParsingRules((rows) => rows.filter(removeRowFilter));
        props.setModifiedParsingRules((rows) => rows.filter(removeRowFilter));
      }
    },
    [props.setDisplayParsingRules, props.setModifiedParsingRules]
  );

  const onRowEditComplete = (e: DataTableRowEditCompleteEvent) => {
    const parsingRulesCopy = [...props.displayParsingRules];
    const newData = e.newData as ParsingRule;
    const itemIndex = props.displayParsingRules.findIndex(
      (item) => item.id === e.data.id
    );

    newData.sourceString = newData.sourceString.trim();
    newData.replacementString = newData.replacementString.trim();

    if (
      newData.status &&
      JSON.stringify(newData) ==
        JSON.stringify(props.displayParsingRules[itemIndex])
    ) {
      // verify a change was made, return if not
      return;
    }

    if (props.showEditConfirmation) {
      // edit in "Confirm changes" table view
      parsingRulesCopy[itemIndex] = newData;
      props.setModifiedParsingRules(parsingRulesCopy);
    } else {
      // edit normal table view
      if (!newData.status) {
        // When term is first being added, it's status would be `null`, until 'saved' locally
        newData.status = STATUS.NEW_TERM;
      }

      if (newData.status !== STATUS.NEW_TERM) {
        newData.status = STATUS.EDIT;
      }
      parsingRulesCopy[itemIndex] = newData;
      props.setDisplayParsingRules(parsingRulesCopy);
      props.setModifiedParsingRules((prev) => {
        const copyArr = [...prev];
        const changeAtIndex = copyArr.findIndex(
          (rule) => rule.id === newData.id
        );

        if (changeAtIndex === -1) {
          copyArr.push(newData);
        } else {
          copyArr[changeAtIndex] = newData;
        }

        return copyArr;
      });
    }
  };

  const onSelectionChange = (
    changeEvent: DataTableSelectionMultipleChangeEvent<ParsingRule[]>
  ) => {
    const selectedParsingRules = changeEvent.value;
    if (props.setSelectedItems && props.setDataTableSelection) {
      if (currentConsumer.isLioAdmin) {
        // for lio admin all parsing rules can be selected/edited/deleted
        props.setSelectedItems(selectedParsingRules);
        props.setDataTableSelection(selectedParsingRules);
      } else {
        // for non lio admin some rules can not be selected
        // We filter out glossary rules that are disabled for CSSP editing,
        // as we don't want those rules to be edited or deleted through CSSP.
        const filteredSelectedItems = selectedParsingRules.filter(
          (parsingRule: ParsingRule) =>
            !parsingRule.disabledForSelfServicePortal
        );
        props.setSelectedItems(filteredSelectedItems);
        // For DataTableSelection we want parsing rules that are `disabledForSelfServicePortal` to be always selected
        // to make sure "Select All" checkbox is checked when all items are selected
        // (`disabledForSelfServicePortal`) rules can not be selected by user per business requirements
        const disabledParsingRules = props.displayParsingRules.filter(
          (item) => item.disabledForSelfServicePortal
        );
        const userSelectedParsingRules = changeEvent.value.filter(
          (item: ParsingRule) => !item.disabledForSelfServicePortal
        );
        props.setDataTableSelection([
          ...disabledParsingRules,
          ...userSelectedParsingRules,
        ]);
      }
    }
  };

  const emptyMessage = React.useMemo(() => {
    return props.displayParsingRules.length == 0 ? (
      <div
        {...getDataTestIdPtObject("noGlossaryTerms")}
        className="text-center my-8"
      >
        <h6>You don’t currently have any glossary items</h6>
        {hasPermission(PermissionGroup.GLOSSARY, Permission.ADD) && (
          <>
            <p className="text-sm text-secondary">
              Glossary terms for{" "}
              <span {...getDataTestIdPtObject("selectedSourceLocaleName")}>
                {selectedLanguagePair?.sourceLocaleName}
              </span>
              &nbsp;to&nbsp;
              <span {...getDataTestIdPtObject("selectedTargetLocaleName")}>
                {selectedLanguagePair?.targetLocaleName}
              </span>{" "}
              will appear here.
              <br />
              Start creating by adding your first term.
            </p>
            <Button
              pt={{
                root: getDataTestIdPtObject("addNewRowBtn"),
              }}
              label="Add new row"
              onClick={props.addNewRow}
            />
          </>
        )}
      </div>
    ) : (
      <div
        {...getDataTestIdPtObject("noFilteringMatch")}
        className="text-center my-8"
      >
        <h6>Filters did not match any glossary items</h6>
        <p className="text-sm text-secondary">
          You can clear all filters by clicking the button below.
        </p>
        <Button
          pt={{ root: getDataTestIdPtObject("clearAllFiltersBtn") }}
          severity="secondary"
          outlined
          label="Clear filters"
          onClick={clearFilters}
        />
      </div>
    );
  }, [
    selectedLanguagePair,
    props.addNewRow,
    props.displayParsingRules,
    filters,
  ]);

  const sortFn = useCallback(
    (field: string) => (sortEvent: ColumnSortEvent) => {
      return [...sortEvent.data].sort((a, b) => {
        if (a.status === null || b.status === null) {
          // All new terms which are in edit mode should be on top of the page, until saved into state.
          if (a.status !== b.status) {
            return a.status === null ? -1 : 1;
          }

          return 0;
        }

        const aValue = a[field].toString();
        const bValue = b[field].toString();

        let comparisonLocale = "en";
        if (field === GLOSSARY_TABLE_COLUMN_FIELD.source) {
          comparisonLocale =
            selectedLanguagePair?.sourceLocaleName.slice(0, 2) || "";
        }
        if (field === GLOSSARY_TABLE_COLUMN_FIELD.replacement) {
          comparisonLocale =
            selectedLanguagePair?.targetLocaleName.slice(0, 2) || "";
        }

        return compareLocaleSensitive(
          aValue,
          bValue,
          comparisonLocale,
          true,
          sortEvent.order || undefined
        );
      });
    },
    [props.setDisplayParsingRules, selectedLanguagePair]
  );

  return (
    <>
      <DataTable
        size="small"
        emptyMessage={emptyMessage}
        filters={filters}
        filterDisplay="row"
        value={props.displayParsingRules}
        editingRows={props.editingRows}
        onRowEditChange={props.setEditingRows && onRowEditChange}
        showGridlines
        rows={10}
        paginator
        editMode="row"
        selectionMode="checkbox"
        loading={isLoadingFetchParsingRules}
        dataKey="id"
        onRowEditComplete={onRowEditComplete}
        onRowEditCancel={onRowEditCancel}
        selection={props.dataTableSelection as DataTableValueArray}
        onSelectionChange={onSelectionChange}
        rowEditValidator={validateRowEdit}
        selectionAutoFocus={false}
        pt={{
          root: {
            id: "glossaryTable",
          },
          paginator: {
            lastPageButton: getDataTestIdPtObject("lastPageBtn"),
            firstPageButton: getDataTestIdPtObject("firstPageBtn"),
            nextPageButton: getDataTestIdPtObject("nextPageBtn"),
            prevPageButton: getDataTestIdPtObject("prevPageBtn"),
          },
          headerRow: {
            ...getDataTestIdPtObject("tableHeaderRow"),
          },
          thead: {
            className: "glossaryTableHeader",
          },
        }}
        rowClassName={(item) => {
          /* rowClassName callback should return { "className": boolean } */
          return {
            csspEditingDisabled:
              item.disabledForSelfServicePortal && !currentConsumer.isLioAdmin,
          };
        }}
      >
        {!props.showEditConfirmation && (
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
            pt={{
              checkbox: getDataTestIdPtObject("rowSelectCheckbox"),
            }}
          />
        )}
        {hasPermission(PermissionGroup.GLOSSARY, Permission.EDIT) && (
          <Column rowEditor />
        )}
        {selectedLanguagePair &&
          getColumns(selectedLanguagePair).map(
            ({
              field,
              header,
              lioAdminColumn,
              dataType,
              filterPlaceholder,
            }) => {
              const isSourceColumn =
                field == GLOSSARY_TABLE_COLUMN_FIELD.source;
              const isReplacementColumn =
                field == GLOSSARY_TABLE_COLUMN_FIELD.replacement;
              const sourceLocaleIsRightToLeft =
                selectedLanguagePair.isSourceLocaleRtl;
              const targetLocaleIsRightToLeft =
                selectedLanguagePair.isTargetLocaleRtl;

              const displayRightToLeft =
                (isSourceColumn && sourceLocaleIsRightToLeft) ||
                (isReplacementColumn && targetLocaleIsRightToLeft);

              if (lioAdminColumn && !currentConsumer.isLioAdmin) {
                // for non lio admins do not show columns intended for lio admins only
                return null;
              }

              return (
                <Column
                  key={field}
                  field={field}
                  header={header}
                  filterHeaderClassName={
                    dataType == "boolean" ? "booleanValueFilterHeader" : ""
                  }
                  filter
                  sortable
                  editor={(options) => cellEditor(options)}
                  body={getColumnBody(field)}
                  showFilterMenu={false}
                  filterElement={getFilterElement(field)}
                  bodyClassName={getBodyClassName(field)}
                  filterPlaceholder={getFilterPlaceholder(
                    field,
                    filterPlaceholder
                  )}
                  sortFunction={sortFn(field)}
                  pt={{
                    sort: getDataTestIdPtObject(field + "SortBtn"),
                    bodyCell: {
                      ...getDataTestIdPtObject(field + "Cell"),
                      className: displayRightToLeft ? "text-right" : "",
                    },
                  }}
                />
              );
            }
          )}
      </DataTable>
      <Tooltip
        target=".csspEditingDisabled .p-selection-column"
        position="top"
        className="text-sm"
      >
        {/*
          For disabled glossary entries we are hiding selection checkbox through CSS rule
          and instead of it adding an 'info' icon, also using a CSS rule.
          We also hide edit button through CSS rule too, as DataTable does not provide an API to control those elements.
          This tooltip is what is shown on icon hover.
        */}
        This glossary term has additional rules applied on our back-end
        <br />
        and can not be edited or deleted from self-service portal.
        <br />
        Please reach out to Language I/O support to edit this term.
      </Tooltip>
    </>
  );
};
