import React, { useEffect, useCallback, useState } from "react";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useGlossaryStore } from "shared/store/glossary";
import { useConsumerStore } from "shared/store/consumer";
import { useLocalesStore } from "../../../../shared/store/locales";
import { getDataTestIdPtObject } from "../../../../shared/functions/utils";
import useLocalePairSelection from "../../../../shared/hooks/useLocalePairSelection";
import { NO_LANGUAGE_SELECTED } from "../../../../shared/constants";
import { Locale } from "../../../../shared/types/Locale";

export const GlossaryLanguagePairSelect = () => {
  const {
    isLoading: isLoadingFetchLanguagePairs,
    resetSelectedLanguagePair,
    fetchParsingRules,
    parsingRules,
    resetParsingRulesTableData,
    setSelectedLanguagePair,
  } = useGlossaryStore();

  const {
    consumerHasLocales,
    fetchConsumerHasLocales,
    fetchLanguagePairMetadata,
    fetchGlossaryTermsMetadata,
    languagePairMetadata,
    glossaryTermsMetadata,
  } = useConsumerStore();

  const { locales, fetchLocales } = useLocalesStore();

  useEffect(() => {
    fetchLocales();
    fetchConsumerHasLocales();
    fetchLanguagePairMetadata();

    // reset language pairs when component unmounts
    return resetSelectedLanguagePair;
  }, []);

  const {
    sourceLocaleOptions,
    targetLocaleOptions,
    selectedSourceLocaleId,
    setSelectedSourceLocaleId,
    selectedTargetLocaleId,
    setSelectedTargetLocaleId,
  } = useLocalePairSelection(
    consumerHasLocales,
    locales,
    languagePairMetadata,
    glossaryTermsMetadata
  );

  const onSourceLanguageSelect = async (e: DropdownChangeEvent) => {
    setSelectedSourceLocaleId(e.value);
    setSelectedTargetLocaleId(NO_LANGUAGE_SELECTED);
    resetSelectedLanguagePair();
    resetParsingRulesTableData();
    try {
      setIsLoadingTermsMetadata(true);
      await fetchGlossaryTermsMetadata(e.value);
    } finally {
      setIsLoadingTermsMetadata(false);
    }
  };

  const onTargetLanguageSelect = (e: DropdownChangeEvent) => {
    setSelectedTargetLocaleId(e.value);
  };

  const [isLoadingTermsMetadata, setIsLoadingTermsMetadata] = useState(false);

  const loadParsingRules = useCallback(() => {
    if (
      selectedSourceLocaleId !== NO_LANGUAGE_SELECTED &&
      selectedTargetLocaleId !== NO_LANGUAGE_SELECTED
    ) {
      const findLocaleById =
        (id: number) => (item: (typeof sourceLocaleOptions)[number] | Locale) =>
          item?.id === id;
      setSelectedLanguagePair({
        sourceLocaleId: selectedSourceLocaleId,
        targetLocaleId: selectedTargetLocaleId,
        sourceLocaleName:
          sourceLocaleOptions.find(findLocaleById(selectedSourceLocaleId))
            ?.name || "",
        targetLocaleName:
          sourceLocaleOptions.find(findLocaleById(selectedTargetLocaleId))
            ?.name || "",
        isSourceLocaleRtl:
          locales.find(findLocaleById(selectedSourceLocaleId))?.isRtl || false,
        isTargetLocaleRtl:
          locales.find(findLocaleById(selectedTargetLocaleId))?.isRtl || false,
      });

      fetchParsingRules(selectedSourceLocaleId, selectedTargetLocaleId);
    }
  }, [selectedSourceLocaleId, selectedTargetLocaleId]);

  const sourceLanguageOptionTemplate = (option: any) => {
    return (
      <div className="flex justify-content-between">
        <div>{option.label}</div>
        {option.metadata.languagePairsCount > 0 && (
          <span className="text-500 text-xs">
            {`${option.metadata.languagePairsCount} language ${
              option.metadata.languagePairsCount === 1 ? "pair" : "pairs"
            }`}
          </span>
        )}
      </div>
    );
  };

  const targetLanguageOptionTemplate = (option: any) => {
    return (
      <div className="flex justify-content-between">
        <div>{option.label}</div>
        {option.metadata.termsCount > 0 && (
          <span className="text-500 text-xs">
            {`${option.metadata.termsCount}  ${
              option.metadata.termsCount === 1 ? "term" : "terms"
            }`}
          </span>
        )}
      </div>
    );
  };

  return (
    <div className="container">
      <div className="row">
        {!parsingRules.length && (
          <h5 className="primary-blue">
            Please select a source and target language
          </h5>
        )}
      </div>
      {isLoadingFetchLanguagePairs ? (
        <></>
      ) : (
        <>
          <div className="row">
            <div className="col">
              <div className="row justify-content-center">
                <span>Source Language</span>
                <div className="row mt-2">
                  <Dropdown
                    id="sourceLang"
                    value={selectedSourceLocaleId}
                    options={sourceLocaleOptions}
                    onChange={onSourceLanguageSelect}
                    optionLabel="label"
                    placeholder="Select Language"
                    itemTemplate={sourceLanguageOptionTemplate}
                    disabled={isLoadingTermsMetadata}
                    pt={{
                      select: getDataTestIdPtObject("sourceLanguageSelect"),
                      list: getDataTestIdPtObject("sourceLanguageList"),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-auto align-self-center mt-4 bg-white opacity-70">
              {isLoadingTermsMetadata ? (
                <i className="pi pi-spin pi-spinner text-4xl" />
              ) : (
                <i className="pi pi-arrow-right" />
              )}
            </div>
            <div className="col">
              <div className="row justify-content-center">
                <span>Target Language</span>
                <div className="row mt-2">
                  <Dropdown
                    id="targetLang"
                    value={selectedTargetLocaleId}
                    options={targetLocaleOptions}
                    onChange={onTargetLanguageSelect}
                    optionLabel="label"
                    placeholder="Select Language"
                    itemTemplate={targetLanguageOptionTemplate}
                    disabled={isLoadingTermsMetadata}
                    pt={{
                      select: getDataTestIdPtObject("targetLanguageSelect"),
                      list: getDataTestIdPtObject("targetLanguageList"),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col flex justify-content-end">
              <Button
                label="Load Glossary"
                disabled={
                  selectedSourceLocaleId === NO_LANGUAGE_SELECTED ||
                  selectedTargetLocaleId === NO_LANGUAGE_SELECTED
                }
                onClick={loadParsingRules}
                {...getDataTestIdPtObject("loadGlossaryBtn")}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
