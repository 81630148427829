import { linguistnowApiInstance } from "./index";

export const GlossaryAPI = {
  exportGlossaryByLanguagePair: async (
    sourceLocale: number,
    targetLocale: number
  ) => {
    const data = { sourceLocale, targetLocale };

    return linguistnowApiInstance({
      url: "linguistnow/glossary/export",
      method: "POST",
      data,
      responseType: "blob",
    });
  },
  exportAllGlossaries: async () => {
    return linguistnowApiInstance({
      url: "linguistnow/glossary/exportAll",
      method: "POST",
      responseType: "blob",
    });
  },
  importGlossaryByLanguagePair: async (file: File) => {
    const data = new FormData();
    data.append("file", file);
    return linguistnowApiInstance({
      url: "linguistnow/glossary/import",
      method: "POST",
      data,
    });
  },
};
