import React, { useState } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { SyntheticEvent, useRef } from "react";
import { useGlossaryStore } from "../../../../shared/store/glossary";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";

const Actions = () => {
  const {
    selectedLanguagePair,
    parsingRules,
    exportByLanguagePair,
    importGlossaryByLanguagePair,
  } = useGlossaryStore();

  const menuButton = useRef<Menu>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const toast = useRef<Toast>(null);

  const exportAllTerms = async () => {
    try {
      setIsLoading(true);
      if (selectedLanguagePair) {
        const response = await exportByLanguagePair(
          selectedLanguagePair?.sourceLocaleId,
          selectedLanguagePair?.targetLocaleId
        );
        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition
          ? contentDisposition.split("filename=")[1].trim().replace(/"/g, "")
          : "glossaries.zip";

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/zip" })
        );
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error(
          "Failed to load exportByLanguagePair",
          selectedLanguagePair
        );
      }
    } catch (error) {
      console.error("Download error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (event: { files: File[] }) => {
    if (event.files && event.files.length > 0) {
      try {
        setIsLoading(true);
        const response = await importGlossaryByLanguagePair(event.files[0]);
        if (response.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: "Glossary terms imported successfully",
            life: 3000,
          });
        }
      } catch (error) {
        console.error("Glossary import failed:", error);
      } finally {
        setIsLoading(false);
        setIsModalVisible(false);
      }
    }
  };

  const actionItems = [
    {
      label: "Import",
      command: async () => {
        setIsModalVisible(true);
      },
    },
    {
      label: "Export all terms (.csv)",
      command: async () => {
        await exportAllTerms();
      },
      disabled: parsingRules.length === 0,
    },
  ];

  const handleOnClick = (event: SyntheticEvent) => {
    if (menuButton) {
      menuButton?.current?.toggle(event);
    }
  };

  return (
    <div>
      <Menu
        model={actionItems}
        popup
        ref={menuButton}
        id="popup_menu_actions"
      />
      <Button
        loading={isLoading}
        outlined
        severity="secondary"
        label="Actions"
        icon="pi pi-caret-down"
        iconPos="right"
        onClick={handleOnClick}
        aria-controls="popup_menu_actions"
        aria-haspopup
      />
      {/* Modal for Import */}
      <Toast ref={toast} />
      <Dialog
        header="Import Glossary Terms"
        visible={isModalVisible}
        style={{ width: "60vw" }}
        onHide={() => setIsModalVisible(false)}
      >
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <ProgressSpinner />
          </div>
        ) : (
          <FileUpload
            name="glossaryFileUpload"
            accept=".csv"
            customUpload
            uploadHandler={handleFileUpload}
            chooseLabel="Choose File"
          />
        )}
      </Dialog>
    </div>
  );
};
export default Actions;
