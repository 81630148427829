import React, { useState } from "react";
import { Button } from "primereact/button";
import { DataTableEditingRows } from "primereact/datatable";
import { ParsingRule } from "../../../../shared/types/ParsingRule";
import { useGlossaryStore } from "../../../../shared/store/glossary";
import { GlossaryDataTable } from "./GlossaryDataTable";
import { STATUS } from "../../utils/GlossaryTableConstants";
import { getDataTestIdPtObject } from "../../../../shared/functions/utils";
import { useConsumerStore } from "../../../../shared/store/consumer";
import {
  Permission,
  PermissionGroup,
} from "../../../../shared/types/UserPermission";
import Actions from "./Actions";
const GlossaryProductionTermsTabPanelBody = () => {
  const { parsingRules } = useGlossaryStore();
  const { hasPermission, currentConsumer } = useConsumerStore();

  const [displayParsingRules, setDisplayParsingRules] =
    React.useState<ParsingRule[]>(parsingRules);

  const [modifiedParsingRules, setModifiedParsingRules] = React.useState<
    ParsingRule[]
  >([]);

  const [selectedItems, setSelectedItems] = React.useState<ParsingRule[]>([]);
  // `selectedItems` store selected glossary rules for "Delete" or "Revert" actions.
  // `dataTableSelection` is used to store DataTable component's selection, separate from `selectedItems`,
  // to make sure "Select All" functionality works properly for parsing rules with `disabledForSelfServicePortal == true`.
  // More context: there is a "Select All" checkbox in primereact's DataTable, it's controlled internally by the library.
  // For this "Select All" checkbox to be checked when all items are selected (except the items with
  // `disabledForSelfServicePortal == true`, which can not be selected per business requirements),
  // we make DataTable think that parsing rules with `disabledForSelfServicePortal == true` are all selected also.
  const [dataTableSelection, setDataTableSelection] = useState<ParsingRule[]>(
    []
  );

  // TODO: make selecteditems and dataTableSlection one by filtering at the Delete/Revert button logic level.

  const [editingRows, setEditingRows] = React.useState<DataTableEditingRows>(
    {}
  );

  const [showEditConfirmation, setShowEditConfirmation] =
    React.useState<boolean>(false);

  const resetState = () => {
    setModifiedParsingRules([]);
    setSelectedItems([]);
    setDataTableSelection([]);
    setEditingRows({});
    setShowEditConfirmation(false);
  };

  // this allows new edits to appear on the UI after they are made, before they are submitted
  React.useEffect(() => {
    setDisplayParsingRules(parsingRules);
    resetState();
  }, [parsingRules]);

  const addNewRow = () => {
    const newRule = {
      id: window.crypto.randomUUID(),
      sourceString: "",
      replacementString: "",
      regex: "",
      ruleType: "GLOSSARY_PRE",
      // we need this to be undefined when creating a new glossary, otherwise it is showing as 0
      replacementOrder: undefined as unknown as string,
      applyNoTranslateTag: false,
      caseInsensitive: false,
      readOnly: false,
      // When a new term is added it has an interim status, thus having `status == null`.
      // All new terms are added in edit mode, from where user can 'cancel' or 'save' the term.
      // If such term is 'cancelled', it will be completely removed from application sate.
      // If such term is 'saved', it receives "New Term" status.
      status: null,
    };
    setDisplayParsingRules((prev) => {
      return [newRule, ...prev];
    });
    setModifiedParsingRules((prev) => {
      return [newRule, ...prev];
    });
    setEditingRows((prev) => ({ ...prev, [newRule.id]: true }));
  };

  const deleteParsingRules = React.useCallback(() => {
    const selectedParsingRules = selectedItems;
    const deletedParsingRules = [] as ParsingRule[];

    const newDisplayParsingRules = displayParsingRules.map((displayItem) => {
      const isSelected = selectedParsingRules.find(
        ({ id: selectedId }) => selectedId === displayItem.id
      );
      if (isSelected) {
        const modifiedParsingRule = {
          ...displayItem,
          status: STATUS.DELETE,
        };
        deletedParsingRules.push(modifiedParsingRule);

        if (displayItem.status === STATUS.NEW_TERM) {
          return null;
        }
        return modifiedParsingRule;
      }
      return displayItem;
    });

    setDisplayParsingRules(
      newDisplayParsingRules.filter((item) => item) as ParsingRule[]
    );
    setModifiedParsingRules((prevState) => {
      const newState = [...prevState] as ParsingRule[];
      deletedParsingRules.forEach((deletedItem) => {
        const previouslyModifiedItem = prevState.find(
          ({ id: modifiedId }) => modifiedId === deletedItem.id
        );

        if (previouslyModifiedItem) {
          if (previouslyModifiedItem?.status === STATUS.NEW_TERM) {
            const index = newState.findIndex(
              ({ id }) => id === previouslyModifiedItem.id
            );
            delete newState[index];
            return;
          }

          previouslyModifiedItem.status = STATUS.DELETE;
        } else {
          newState.push(deletedItem);
        }
      });

      return newState.filter((item) => item);
    });

    setSelectedItems([]);
    setDataTableSelection([]);
  }, [selectedItems, displayParsingRules]);

  const revertDeletedParsingRules = () => {
    const revertedRulesMap = (selectedItems as ParsingRule[]).reduce(
      (acc, currentValue) => {
        if (currentValue.id) {
          acc[currentValue.id] = currentValue;
        }
        return acc;
      },
      {} as Record<string, ParsingRule>
    );

    setDisplayParsingRules((prevState) => {
      return prevState.map((displayItem) => {
        if (displayItem.id && revertedRulesMap[displayItem.id]) {
          displayItem.status = STATUS.REVERTED;
        }
        return displayItem;
      });
    });

    setModifiedParsingRules((prevState) => {
      const newState = prevState.map((modifiedItem) => {
        if (modifiedItem.id && revertedRulesMap[modifiedItem.id]) {
          return null;
        }
        return modifiedItem;
      });

      return newState.filter((item) => item) as ParsingRule[];
    });

    setSelectedItems([]);
    setDataTableSelection([]);
  };

  return (
    <div className="mt-2">
      <div className="flex justify-content-end mb-2 gap-1">
        {!showEditConfirmation &&
          hasPermission(PermissionGroup.GLOSSARY, Permission.ADD) && (
            <Button
              label="Add new row"
              onClick={addNewRow}
              pt={{ root: getDataTestIdPtObject("addNewRowBtn") }}
            />
          )}
        {currentConsumer.isLioAdmin && <Actions />}
      </div>
      <GlossaryDataTable
        displayParsingRules={displayParsingRules}
        modifiedParsingRules={modifiedParsingRules}
        setModifiedParsingRules={setModifiedParsingRules}
        setDisplayParsingRules={setDisplayParsingRules}
        addNewRow={addNewRow}
        showEditConfirmation={showEditConfirmation}
        setShowEditConfirmation={setShowEditConfirmation}
        editingRows={editingRows}
        setEditingRows={setEditingRows}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        deleteParsingRules={deleteParsingRules}
        revertParsingRules={revertDeletedParsingRules}
        dataTableSelection={dataTableSelection}
        setDataTableSelection={setDataTableSelection}
      />
    </div>
  );
};

export default GlossaryProductionTermsTabPanelBody;
